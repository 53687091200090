body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 1000px;
    margin-top: 10px;
    margin: 10px;
}

.port {
    width: 40%;
    height: 40%;
    margin: 10px;
    border-radius: 5%;
    box-shadow: 10px 5px 5px rgb(218, 218, 218);
}

.statement {
    display: flex;
    flex-direction: row;
}

.statement p {
    margin: 10px;
}

.hello {
    display: flex;
    margin-left: 10px;
    font-size: 32px;
}

.begin {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.hidden-state {
    margin-top: 20px;
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s ease;
    transition-delay: 200ms;
}

.show-state {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}

@media screen and (max-width: 769px) {

    .about {
        width: 85%;
    }
    .statement {
        display: flex;
        flex-direction: column;
    }

    .hello {
        display: block;
        margin-left: 10px;
        align-self: center;
        font-size: 24px;
        flex: none;
    }

    .begin p {
        text-align: center;
    }

    .port {
        width: 80%;
        margin: 10px;
        align-self: center;
        border-radius: 5%;
        box-shadow: 10px 5px 5px rgb(218, 218, 218);
    }

    #about-one {
        order: 1;
    }
}