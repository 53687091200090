body {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.container {
    display: flex;
    align-items: center;
}

.Typewriter {
    left: 25px;
    position: relative;
    font-size: 3.5em;
    text-align: center;
    font-weight: bold;
    font-family: 'DM Serif Display', serif;
}

#header {
    display: flex;
    width: 65%;
    height: 100%;
    position: relative;
    justify-content: center;
    margin-top: 30px;
    overflow: hidden; 
}

#intro-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    width: 40%;
}

#intro-text p {
    font-size: 18px;
    text-align: center;
    position: relative;
    left: 10px;
    margin-bottom: 15px;
}

.hidden {
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    transition: all 1s ease;
    transition-delay: 200ms;
}

.show {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.hidden:nth-child(2) {
    transform: translateY(100%);
    transition-delay: 1000ms;
}

.show:nth-child(2) {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    text-align: center;
    width: 80%;
}

.hidden:nth-child(3) {
    transform: translateY(100%);
    transition-delay: 1200ms;
}

.show:nth-child(3) {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    text-align: center;
    width: 80%;
}

.hidden:nth-child(4) {
    transform: translateY(100%);
    transition-delay: 1400ms;
}

.show:nth-child(4) {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    text-align: center;
    width: 80%;
}

.hidden:nth-child(5) {
    transform: translateY(100%);
    transition-delay: 1600ms;
}

.show:nth-child(5) {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
    text-align: center;
}

.image-container {
    width: 40%;
    height: 40%;
    margin-left: 50px;
    border-radius: 5%;
    overflow: hidden;
    display: flex;
    position: relative;
    right: 10px;
}

.image-container img .hidden {
    transform: translateY(100%);
    transition: transform 0.3s ease;
    border-radius: 5%;
}

.image-container .show {
    box-shadow: 10px 5px 5px rgb(218, 218, 218);
    margin: 15px;
    border-radius: 5%;
    overflow: hidden;
    transform: translateY(0);
    transition-delay: 800ms;
}
  

.hidden#icons {
    transform: translateY(100%);
    transition-delay: 3200ms;
}

.show#icons {
    opacity: 1;
    filter: blur(0);
    transform: translateY(0);
}

#icons a {
    margin: 10px;
    position: relative;
    color: black;
}

#icons svg {
    width: 10%;
    height: 40%;
}

#contact-link {
    font-size: 1.5em;
    color: black;
    text-decoration: none;
    position: relative;
    bottom: 10px;
}

#contact-link::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: transparent;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0% 100%;
    transition: transform 0.3s ease;
}

#contact-link:hover::before {
    background-color: #555;
    transform: scaleX(1);
}

@media screen and (max-width: 769px) {

    body {
        width: 100%;
        height: 100%;
    }
    
    #header {
        bottom: 20px;
        width: 80%;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
    }

    .image-container {
        margin-left: 0px;
        width: 100%;
    }

    #intro-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        right: 0px;
        order: 2;
    }

    #intro-text p{
        left: 0px;
        font-size: 15px;
    }

    .Typewriter {
        left: 5px;
        letter-spacing: -2px;
        font-size: 2em;
    }

    .image-container {
        right: 0px;
    }


}