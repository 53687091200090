#email {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 300px;
    height: 100%;
    width: 100%;
    font-size: 1em;
    font-weight: bold;
    font-family: 'DM Serif Display', serif;
}

#email a {
    text-decoration: none;
}

.hidden-ico {
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 0;
    filter: blur(5px);
    transform: translateY(100%);
    width: 100%;
    transition: all 1s ease;
    transition-delay: 400ms;
}

.show-ico {
    opacity: 1;
    filter: blur(0);
    transform: translateX(0);
}

.show-ico a {
    margin: 5px;
}


@media screen and (max-width: 769px) {
    .Typewriter {
        font-size: 2em;
        letter-spacing: -2px;
    }

    #email {
        width: 80%;
    }

    .hidden-ico {
        letter-spacing: -1px;
    }

    .hidden-ico p {
        width: 800px;
        overflow: hidden;
    }
}