* {
    box-sizing: border-box;
}

body {
    margin: 0;
}

.container {
    justify-content: center;
}

.nav {
    background-color: transparent;
    color: black;
    font-weight: bold;
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
    margin-top: 5px;
}

.site-title {
    font-family: 'DM Serif Display', serif;
    font-size: 2rem;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav li {
    position: relative;
}

.nav a {
    color: inherit;
    text-decoration: none;
    height: 100%;
    display: flex;
    align-items: center;
    padding: .25rem;
}

.nav a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: transparent;
    bottom: 0;
    left: 0;
    transform: scaleX(0);
    transform-origin: 0 100%;
    transition: transform 0.3s ease;
}

.nav a:hover::before,
.nav li.active a::before {
    background-color: #555;
    transform: scaleX(1);
}

#mobile {
    display: none;
}

#mobile i {
    align-items: center;
}

@media screen and (max-width: 769px) {

    #mobile {
        display: flex;
    }

    .container {
        justify-content: center;
    }

    .nav ul {
        padding-bottom: 10px;
        margin-top: -20px;
        list-style: none;
        display: none;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: row;
    }

    .nav {
        display: flex;
        width: 90%;
        flex-direction: column;
        transition: 0.2s
    }

    #bar {
        display: flex;
        font-size: 1.75rem;
    }

    .nav.clicked ul {
        display: flex;
        flex-direction: row;
        transition: 0.5s;
    }

    .mobile-head {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-direction: row;
    }

    .site-title {
        letter-spacing: -2px;
    }
}